// TODO: isolate program > composables/smaller files before moving to composition API

<template>
  <h3>VIZBI <span>Public Talks</span></h3>
  <div v-if="timeZoneDiffers" id="timezone">
    <!-- only show this div if the local and conference time zones differ -->
    Time zone:
    <Dropdown
      :placeholder="timeZone"
      :options="[timeZoneConference, timeZoneLocal].sort()"
      :selected="timeZone"
      :tag="false"
      @update="switchTimeZone" />
  </div>
  <br />
  <div v-if="notLive" id="signin">
    This page will provide access the Masterclass and VIZBI videos after they have streamed.
  </div>
  <div v-else class="clearBoth">
    <p class="intro">
      This page gives access to the Zoom Webinar links to access public talks freely streamed during
      the VIZBI event.
      <!-- <span v-if="showLinkAt < 1000"
        >Zoom links for each event will appear below {{ showLinkAt }} minutes before the start time.</span
      > -->
    </p>
    <p class="intro">
      We recommend you
      <a href="https://support.zoom.us/hc/en-us/articles/201362233">use this link</a> to determine
      if you have the <a href="https://zoom.us/download">latest Zoom desktop client</a>.
    </p>
    <!-- <p class="intro">
      If you need help, you can contact the VIZBI support team via
      <a href="https://app.purechat.com/w/byqeil">this chat link</a>
    </p> -->
    <LiveEvent
      v-for="eventName in ['VIZBI 2025']"
      :key="eventName"
      :event-name="eventName"
      :time-zone="timeZone"
      :public="true" />
  </div>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line

const notLive = false; // true

import Dropdown from "@/components/Dropdown";
import LiveEvent from "@/components/LiveEvent";
import history from "@/assets/history.json";
const year = history.latestYear;
const timeZoneConference = history.years[year];
const timeZoneLocal = Intl.DateTimeFormat().resolvedOptions().timeZone;
const {DateTime} = require("luxon");

//let showLinkAt = 1500000; // by default, show link 15 mins prior

import {ref} from "vue";
const [timeZone, publicEvents] = [ref(""), ref([])];

import {computed} from "vue";
const timeZoneDiffers = computed(() => {
  // tests if the local time zone is the same as the conference time zone
  if (!timeZoneConference) {
    // early return if the original program hasn't been mounted yet
    return false;
  }
  let localTime = DateTime.local(); // get current time in local timeZone
  log(`timeZoneDiffers: localTime (${localTime})`);
  let timeInConferenceZone = localTime.setZone(timeZoneConference);
  // rezone to conference without changing timestamp;
  log(`timeZoneDiffers: timeInConferenceZone (${timeInConferenceZone})`);
  return timeInConferenceZone.toString() !== localTime.toString();
});

import {onMounted} from "vue";
onMounted(() => {
  log(`onMounted()`);
  setDayAndTimeZoneFromURL();
});

function switchTimeZone(newTimeZone) {
  localStorage.setItem("timeZone", newTimeZone);
  timeZone.value = newTimeZone;
  setTimeZoneInUrl(newTimeZone);
}

import {useRoute} from "vue-router";
const route = useRoute();
function setDayAndTimeZoneFromURL() {
  let parameters = route.hash.replace(/^#/, "").split("&");
  if (parameters[0] && parameters[0].match(/-/)) {
    // if the next remaining parameter is a time zone, ...
    log(`URL includes time zone '${parameters[0]}'`);
    /// overwrite this with the local time zone
    timeZone.value = timeZoneLocal;
    localStorage.setItem("timeZone", timeZoneLocal);
    return;
  } else if (localStorage.getItem("timeZone")) {
    // if no time zone is set in the URL, ...
    timeZone.value = localStorage.getItem("timeZone");
    log(`Using time zone in localStorage: ${timeZone.value}`);
  } else {
    // initially, defaults to conference time zone (not local time zone).
    // user can then change it
    timeZone.value = timeZoneConference;
  }
}
// function formatTime(date) {
//   if (!date) {
//     return;
//   } else if (timeZone.value.match(/London|Australia|America/)) {
//     // use 12 hour clock + AM/PM for these countries
//     return date.toFormat("h:mma").toLowerCase();
//   } else {
//     // use 24 hour clock for all other countries
//     return date.toFormat("T").toLowerCase();
//   }
// }

function setTimeZoneInUrl(timeZone) {
  this.log(`setTimeZoneInUrl(${timeZone})`);
  if (!timeZone) return;
  if (timeZone === timeZoneConference) {
    history.pushState({}, null, route.path);
  } else {
    const hash = timeZone.replace("/", "-");
    history.pushState({}, null, `${route.path}#${hash}`);
  }
}

function signedInTo(userEvents) {
  log(`signedInTo: ${JSON.stringify(userEvents)}`);
  publicEvents.value = userEvents;
}
</script>

<style scoped>
h3 {
  display: inline;
  float: left;
}
#timezone {
  float: right;
  margin-top: 12px; /* same as App.vue/h3 */
  margin-bottom: 8px; /* same as App.vue/h3 */
  font-size: 14px;
  line-height: 33px;
  clear: right; /* https://stackoverflow.com/q/3415635 */
}
.clearBoth {
  clear: both; /* needed to clear two floats above */
  margin-top: 5px;
}
p.intro {
  font-weight: 300;
  /* font-style: italic; */
}
div.description {
  text-align: left;
  text-decoration: none;
  font-size: 1.1rem;
  color: black;
}
h3 span {
  font-weight: 300;
}
</style>
