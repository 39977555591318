// TODO: isolate program > composables/smaller files before moving to composition API

<template>
  <h4>{{ eventName }}</h4>
  <!-- <div class="zoom-container">
    <button class="zoom-button" @click="zoomSignIn">Zoom Sign-in</button>
  </div> -->
  <div v-if="host.match(/^local|^dev|^192/)">
    <!-- each click jumps to just before next event -->
    <button v-if="test_program.length" @click="reset">Reset</button>
    <button v-else @click="run_test">Run test</button>
  </div>
  <p>
    Shortly after the live stream, videos from this event will be available on the
    <router-link to="/Videos">VIZBI video page</router-link>.
  </p>
  <div id="events">
    <!-- Need key to change when items change. Was: `${i}.${reSorted}` -->
    <!-- was: (item, i) -->
    <template v-for="item in items" :key="item.startTimeStamp">
      <!-- <p>{{ JSON.stringify(item, null, 2) }}</p> -->
      <EventItem
        v-if="item.online && item.public"
        :item="item"
        :show-link-at="showLinkAt"
        @ended="eventHasEnded" />
    </template>
  </div>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line

const props = defineProps(["eventName", "timeZone", "public"]);

const {DateTime} = require("luxon");
import EventItem from "@/components/EventItem";
import history from "@/assets/history.json";
const year = history.latestYear;
const timeZoneConference = history.years[year];
const timeZoneLocal = Intl.DateTimeFormat().resolvedOptions().timeZone;
const host = window.location.host;
let showLinkAt = 15; // by default, show link 15 mins prior

import {ref} from "vue";
const reSorted = ref(0); // counts re-sorting events
const programOriginal = ref([]); // for testing
const test_program = ref([]); // for testing

//need?
import stringify from "json-stringify-safe";

import {computed} from "vue";
// const session = computed(() => {
//   if (props.eventName.match("Masterclass")) return "Masterclass";
//   return "VIZBI";
// });

const program = computed(() => {
  log(`program()`);
  let output = [];
  let previousEndTime = "";
  let program = programOriginal.value; // local copy of original program
  function col(heading) {
    // return the index of the column with this heading
    return program[0].indexOf(heading);
  }
  // early return if the original program hasn't been mounted yet
  if (!Object.keys(programOriginal.value)) {
    return;
  }
  if (Object.keys(programOriginal.value).length === 0) {
    return;
  }
  //log(`Found events: ${Object.keys(programOriginal.value).length}`);

  for (let i = 1; i < program.length; i++) {
    let item = {}; // One event
    log(`program item = ${program[i]}`);
    //if (!program[i][col("Mode")].includes("Online")) continue; // skip
    item.online = program[i][col("Mode")].includes("Online");
    log(`program item is online`);
    if (props.eventName.includes("Masterclass")) {
      // for the Masterclass, skip all non-Masterclass items
      if (!program[i][col("Category")].includes("Masterclass")) continue;
      log(`program item is part of Masterclass`);
    }
    if (!props.eventName.includes("Masterclass")) {
      // for VIZBI, skip all Masterclass items
      if (program[i][col("Category")].includes("Masterclass")) continue;
      log(`program item is part of VIZBI`);
    }
    item.name = program[i][col("Name")];
    item.title = program[i][col("Talk/Role/Event")];
    // if (!item.name && !item.title.match(/^meet|Q&A/i)) {
    // skip event items with no speaker or not beginning with 'meet'
    // continue;
    // }
    if (!program[i][col("Date")]) continue; // skip items with no date
    item.date = program[i][col("Date")];
    item.public = program[i][col("Public")] === "TRUE";
    item.description = program[i][col("Description")];
    item.stream = program[i][col("Stream")];
    item.video = program[i][col("Video")];
    if (!program[i][col("Duration/Time")]) continue; // skip session chairs
    let eventTime = program[i][col("Duration/Time")];
    if (eventTime.match("-")) {
      let [start, end] = eventTime.split(/\s*-\s*/);
      log(`Timed event '${item.title}, on: ${item.date}, start: ${start}, end: ${end}`);
      start = start.split(":");
      end = end.split(":");
      let date = item.date.split("/");
      item.start = DateTime.fromObject(
        {
          year: date[2],
          month: date[1],
          day: date[0],
          hour: start[0],
          minute: start[1],
          second: 0
        },
        {zone: timeZoneConference}
      );
      item.end = DateTime.fromObject(
        {
          year: date[2],
          month: date[1],
          day: date[0],
          hour: end[0],
          minute: end[1],
          second: 0
        },
        {zone: timeZoneConference}
      );
      if (props.timeZone === timeZoneLocal) {
        //log(`Change to local times: ${timeZoneLocal}`);
        item.start = item.start.toLocal();
        item.end = item.end.toLocal();
      }
    } else {
      let duration = parseInt(program[i][col("Duration/Time")]);
      //log(`Event duration: ${stringify(duration)}`);
      if (!previousEndTime) {
        console.error(`Need a previous event time`);
        continue; // "jumps over" one iteration in the for loop
      }
      item.start = previousEndTime;
      item.end = previousEndTime.plus({minute: duration});
    }
    previousEndTime = item.end; // remember for next event
    item.startTimeStamp = item.start; // remember time stamp for countdown
    item.endTimeStamp = item.end; // remember time stamp for countdown
    log(`startTimeStamp = ${item.startTimeStamp}`);
    // now that times and dates are converted, format them
    item.date = item.start.weekdayLong + ", ";
    item.date += item.start.toFormat("LLL dd");
    item.start = formatTime(item.start);
    item.end = formatTime(item.end);
    item.ended = false; // true => event is over
    //log(`item.end: ${item.end}`);
    output.push(item);
  }
  return output;
});

const items = computed(() => {
  log(`items: reSorted = ${reSorted.value}`);
  let output = [];
  if (test_program.value.length) {
    log(`Running in test mode: using test program`);
    output = test_program.value;
  } else {
    log(`Running in normal mode: using real program`);
    output = program.value;
  }
  if (!reSorted.value) {
    return output;
  }
  log(`check if items need to be (re-)sorted`);
  let [first, second, ...rest] = output;
  log(`live/first = ${stringify(first)}`);
  let i = 1; // prevent infinite loop
  while (first.ended && i <= output.length) {
    log(`first = ${stringify(first)}`);
    log(`second = ${stringify(second)}`);
    log(`first item has ended; move to end`);
    output = [second, ...rest, first];
    [first, second, ...rest] = output;
    i++;
    // log(`item.end: ${item.end}`);
  }
  return output;
});

import {onMounted} from "vue";
onMounted(() => {
  log(`onMounted()`);
  try {
    programOriginal.value = require(`@/assets/years/${year}/Program/program.json`);
  } catch (error) {
    console.error(`Could not load program file: ${error}`);
    // alert(`Could not load program file: ${error}`); // Uncomment if needed
  }
});

function formatTime(date) {
  if (!date) {
    return;
  } else if (props.timeZone.match(/London|Australia|America/)) {
    // use 12 hour clock + AM/PM for these countries
    return date.toFormat("h:mma").toLowerCase();
  } else {
    // use 24 hour clock for all other countries
    return date.toFormat("T").toLowerCase();
  }
}
function run_test() {
  log(`Test button pressed: calculating test program`);
  let i = 1;
  let output = [];
  for (const original_item of program.value) {
    const item = Object.assign({}, original_item);
    item.startTimeStamp = DateTime.now().plus({minutes: i});
    item.end = DateTime.now().plus({minutes: i + 1});
    item.start = formatTime(item.startTimeStamp);
    item.end = formatTime(item.end);
    item.date = item.startTimeStamp.weekdayLong + ", ";
    item.date += item.startTimeStamp.toFormat("LLL dd");
    output.push(item);
    i++;
  }
  // reset sort counter and test_program
  [reSorted.value, test_program.value] = [0, output];
  showLinkAt.value = 0.5; // switch to showLinkAt 1 min prior
}
function reset() {
  showLinkAt.value = 30; // return to showing 15min prior
  // reset sort counter and turn off test
  [reSorted.value, test_program.value] = [0, []];
}
function eventHasEnded(timeStamp) {
  //log(`Live/eventHasEnded(${stringify(timeStamp)})`);
  for (let item of program.value) {
    if (item.startTimeStamp === timeStamp) {
      log(`Live/eventHasEnded: title = ${item.title}`);
      item.ended = true;
      log(`live/item = ${stringify(item)}`);
      reSorted.value++; // triggers items to be re-sorted
      return; // break loop & exit
    }
  }
  console.error(`Live/eventHasEnded: couldn't find matching item`);
}
function zoomSignIn() {
  // default to Masterclass
  let url = "https://usc.zoom.us/webinar/register/WN_-abAQ9pSQji7gDPk5Rvxsw";
  if (props.eventName.includes("VIZBI")) {
    url = "https://usc.zoom.us/webinar/register/WN_W92jZUGYSnO4tVLXrpUCwQ";
  }
  window.open(url, "_blank");
}
</script>

<style scoped>
h3 {
  display: inline;
  float: left;
}
.zoom-container {
  /* Centering the button if needed */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.zoom-button {
  background-color: #2d8cff; /* Zoom's blue color */
  color: white; /* White text */
  border: none; /* Remove default border */
  padding: 10px 20px; /* Padding inside the button */
  font-size: 16px; /* Text size */
  cursor: pointer; /* Pointer cursor on hover */
  border-radius: 5px; /* Slightly rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional: adds a slight shadow for depth */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

#timezone {
  float: right;
  margin-top: 12px; /* same as App.vue/h3 */
  margin-bottom: 8px; /* same as App.vue/h3 */
  font-size: 14px;
  line-height: 33px;
  clear: right; /* https://stackoverflow.com/q/3415635 */
}
#signin {
  clear: both; /* needed to clear two floats above */
  margin-top: 5px;
}
#events {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
p.intro {
  font-weight: 300;
  font-style: italic;
}
div.description {
  text-align: left;
  text-decoration: none;
  font-size: 1.1rem;
  color: black;
}
h3 span {
  font-weight: 300;
}
</style>
