<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <div v-if="year" id="container">
    <h3>Key Dates</h3>
    <!-- https://stackoverflow.com/q/45926982 -->
    <template v-for="(item, key) in items" :key="item">
      <div :class="[past_or_future(item)]">
        <b v-if="item.daysBeforeOld" style="text-decoration: line-through">
          {{ `${date(item.daysBeforeOld)}:` }}
        </b>
        <b v-else-if="item.datesOld" style="text-decoration: line-through"
          >{{ shorten(item.datesOld) }}:</b
        >
        <b v-if="item.daysBefore">{{ date(item.daysBefore) }}:</b>
        <b v-else-if="item.dates">{{ shorten(item.dates) }}:</b>
      </div>
      <div :class="[past_or_future(item)]">
        <span v-if="!key.match(/VIZBI|Masterclass/)">Deadline for </span>
        <!-- to='somewhere' redirect to '/year/somewhere' -->
        <a v-if="item?.link.startsWith('http')" :href="item.link">{{ key }}</a>
        <router-link v-else :to="`${item.link}`">{{ key }}</router-link>
        <!-- span v-if="showCounter(key, item)"> ({{ counter.value }})</span -->
        <span v-if="item.daysBeforeOld || item.datesOld"> (EXTENDED)</span>
      </div>
    </template>
  </div>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
import {ref, computed, onMounted} from "vue";
import {useRoute} from "vue-router";
import {DateTime} from "luxon"; // https://tinyurl.com/luxon-global
import time_zone from "@/assets/history";

const props = defineProps({items: {type: Object, required: true}});

const route = useRoute();
const counter = ref([]);
const counterAlreadyShown = ref(false);
const year = ref(Number(route.params.year));

const startDate = computed(() => {
  // find first item beginning with 'VIZBI' with a date
  let vizbi = Object.keys(props.items).find((key) => key.match(/^VIZBI/) && props.items[key].dates);
  if (!vizbi) return;
  let matches = props.items[vizbi].dates.match(/^(\w+)\s*,*(\d+)/);
  let [monthName, day] = [matches[1], matches[2]];
  let month = new Date(Date.parse(monthName + " 1, 2012")).getMonth() + 1;
  return {year: year.value, month: month, day: day, hour: 9, minute: 0};
});

const startDateInMeetingTimeZone = computed(() => {
  return DateTime.fromObject(startDate.value, {zone: time_zone[year.value]});
});

const startDateInLA = computed(() => {
  let closingDate = structuredClone(startDate.value);
  closingDate.hour = 23;
  closingDate.minute = 59;
  return DateTime.fromObject(closingDate, {zone: "America/Los_Angeles"});
});

const date = (daysBefore) => {
  return startDateInMeetingTimeZone.value.plus({days: daysBefore}).toFormat("MMM d");
};

const shorten = (dateString, format = "MMM d") => {
  const dateRangeMatch = dateString.match(/^(\w+)\s+(\d+)(?:-(\d+))?$/);
  if (!dateRangeMatch) {
    return DateTime.fromFormat(dateString, "MMMM d").toFormat(format);
  }
  const [_, month, startDay, endDay] = dateRangeMatch;
  const shortMonth = DateTime.fromFormat(month, "MMMM").toFormat("MMM");
  return endDay ? `${shortMonth} ${startDay}-${endDay}` : `${shortMonth} ${startDay}`;
};

const past_or_future = (item) => {
  if (item.daysBefore) {
    log(`KeyDates: past_or_future: daysBefore = ${item.daysBefore}`);
    let dueAt = startDateInLA.value.plus({days: item.daysBefore});
    log(`KeyDates: past_or_future: dueAt = ${dueAt}`);
    if (!dueAt.isValid) {
      console.error(`Invalid date: ${dueAt}`);
      return "invalid_date";
    }
    if (dueAt.endOf("day") >= DateTime.now()) {
      if (!counterAlreadyShown.value) {
        counter.value = DateTime.now().diff(dueAt, ["days", "hours", "minutes", "seconds"]);
      }
      return "future";
    } else {
      return "past";
    }
  }
  if (item.dates) {
    log(`KeyDates: past_or_future: dates = ${item.dates}`);
    let endDate = item.dates.match(/^(\w+)\s+(\d+)(?:-(\d+))?$/);
    if (!endDate) {
      return "future";
    }
    let year = startDateInLA.value.toFormat("yyyy");
    let endDay = endDate[3] || endDate[2]; // multi-day || one-day event
    let endDateString = `${endDate[1]} ${endDay}, ${year}`;
    log(`KeyDates: past_or_future: ends ${endDateString}`);
    endDate = DateTime.fromFormat(endDateString, "MMMM d, yyyy");
    log(`KeyDates: past_or_future: endDate = ${endDate}`);
    if (!endDate.isValid) {
      console.error(`Gives invalid date: ${endDate}`);
      return "invalid_date";
    }
    if (endDate.endOf("day") >= DateTime.now()) {
      if (!counterAlreadyShown.value) {
        counter.value = DateTime.now().diff(endDate, ["days", "hours", "minutes", "seconds"]);
      }
      return "future";
    } else {
      return "past";
    }
  }
};

// const showCounter = (key, item) => {
//   log(`showCounter(${key}, ${item.daysBefore})`);
//   if (counterAlreadyShown.value) {
//     log(`showCounter: already shown ${counter.value}`);
//     return true; // false
//   }
//   if (past_or_future(item) === "future") {
//     // show this item
//     counterAlreadyShown.value = true;
//     return true;
//   }
// };
</script>

<style scoped>
/* https://css-tricks.com/snippets/css/complete-guide-grid/ */
div#container {
  display: grid;
  grid-template-columns: minmax(10px, 1fr) 3fr;
  grid-template-rows: auto;
  grid-row-gap: 0.3em;
}
h3 {
  grid-column: 1 / -1; /* spans from column 1 to column -1 (last column) */
  margin: 0; /* remove default margin */
}
b {
  font-weight: 600; /* not quite so bold - 300 for light text */
}
.past {
  text-decoration: line-through;
}
.past,
.past a {
  color: silver; /* switch color */
}
.invalid_date,
.invalid_date a {
  color: red;
}
</style>
